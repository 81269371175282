/* 1. Use a more-intuitive box-sizing model */

*,
*::before,
*::after {
    box-sizing: border-box;
}
/* 2. Remove default margin */
* {
    margin: 0;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}
/* 2. Remove default margin */
* {
    margin: 0;
}
